<template>
  <v-app style="width: 100%">
    <div class="main-div">
      <div class="div-title">
        <center>Students Marks</center>
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                Course name
              </th>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                Course code
              </th>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                  Ass
              </th>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                Cat
              </th>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                Exam
              </th>
              <th style=" font-size: 17px; font-weight: bold;text-transform: capitalize !important;">
                TOT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="mark in marks"
              :key="mark.id"
            >
              <td>{{ mark.title }}</td>
              <td>{{ mark.code }}</td>
              <td>{{ mark.assignment}}</td>
              <td>{{ mark.cat }}</td>
              <td>{{ mark.exam }}</td>
              <td>{{Number(parseFloat(mark.assignment) + parseFloat(mark.cat) + parseFloat(mark.exam) ).toFixed(1)+"/" + mark.points }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'viewStudentMarks',
  data () {
    return {
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
        },
        {
          name: 'Eclair',
          calories: 262,
        },
        {
          name: 'Cupcake',
          calories: 305,
        },
        {
          name: 'Gingerbread',
          calories: 356,
        },
        {
          name: 'Jelly bean',
          calories: 375,
        },
        {
          name: 'Lollipop',
          calories: 392,
        },
        {
          name: 'Honeycomb',
          calories: 408,
        },
        {
          name: 'Donut',
          calories: 452,
        },
        {
          name: 'KitKat',
          calories: 518,
        },
      ],
      marks: [],
    }
  },
  created () {
    this.fetchStudentMarks()
  },
  methods: {
    async fetchStudentMarks () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'show_student_marks',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.marks = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.faculty = []
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>
<style scoped>
.main-div {
  width: 100%;
  border: 2px solid royalblue;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
}
  .div-title {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid royalblue;
    background-color: #abb0c4;
    place-content: center !important;
    padding: 10px;
  }
  .table-heading {
    font-size: 17px; font-weight: bold;text-transform: capitalize !important;
  }
</style>
